.BusRegister {
  font-family: "Varela Round", serif;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: flex-start;
  direction: rtl;

  .FormTitle {
    text-align: right;
    margin-right: 7vw;
    margin-top: 0;
    margin-bottom: 0;
    color: black;
    font-size: 24px;
  }

  .InfoCard {
    display: flex;
    background-color: #d5d5d5;
    justify-content: right;
    border-radius: 15px;
    align-self: flex-start;
    flex-direction: column;
    text-align: right;
    margin: 0 7vw;
    padding-bottom: 15px;

    .InfoCardTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .NoUserText {
        display: flex;
        flex-direction: row;
        align-self: center;
        margin: 0 20px;

        p {
          margin: 10px 0;
        }

        .RegisterText {
          color: blue;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .CancelRegistrationButton {
        align-self: flex-start;
        border: 0;
        background-color: #df1d22;
        color: white;
        border-radius: 10px;
        font-size: 18px;
        padding: 1vh;
        font-family: "Varela Round", serif;
        cursor: pointer;
      }

      .CancelRegistrationButton:hover {
        color: #e7e7e7;
      }
    }

    .StationInfo {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;

      .NavigationLogoLinks {
        display: flex;
        flex-direction: row;
        margin-right: auto;
        margin-top: 0;
        margin-left: 0;

        .NavigationLogo {
          width: 30px;
          height: 30px;
          margin-left: 15px;
          margin-top: 0;
          cursor: pointer;
        }
      }
    }

    p {
      font-size: 16px;
      margin: 0 15px;
    }

    h3 {
      font-size: 16px;
    }

    div {
      margin-bottom: 0;
    }
  }

  .BusForm {
    display: flex;
    flex-direction: column;
    margin-right: 7vw;
    margin-top: 2vh;

    .NumPassengers {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 2vh;
      margin-bottom: 0.3vh;
      max-width: 220px;
    }

    .PhoneLabel {
      display: flex;
      align-items: center;
      margin-top: 1vh;

      .PhoneInfoIcon {
        margin-right: 5px;
      }
    }

    .NumMembers {
      display: flex;
      flex-direction: row;
    }

    .Checkbox {
      display: flex;
      align-items: center;

      .CheckboxInfoIcon {
        margin-right: 15px;
      }
    }
  }
}

.SubmitButton {
  align-self: center;
  margin: 3vh 1vw;
  border: 0;
  background-color: #df1d22;
  color: white;
  border-radius: 15px;
  font-size: 24px;
  padding: 2vh;
  font-family: "Varela Round", serif;
  cursor: pointer;
}