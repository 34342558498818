.Header {
  display: flex;
  flex-direction: column;
  text-align: right;
  background-color: #df1d22;
  color: white;
  font-family: "Varela Round", serif;
  margin-bottom: 0;
  direction: rtl;

  .MainHeader {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    margin-top: 1vh;
    direction: rtl;

    .LogoTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: white;

      .Logo {
        margin-left: 2vw;
        margin-right: 2vw;
        width: 10vw;
        height: 10vw;
      }

      .Title {
        text-align: right;
        font-size: 1.5em;
      }
    }

    .HeaderButton {
      text-align: right;
      font-size: 1em;
      border: 0;
      background-color: #df1d22;
      color: white;
      font-family: "Varela Round", serif;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-right: auto;
      padding-left: 3vw;
      padding-right: 3vw;

      .ProfileIcon {
        margin-left: 10px;
      }
    }

    .HeaderButton:hover {
      color: #e7e7e7;
    }
  }

  .Subtitle {
    margin-right: 3vw;
    text-align: right;
    font-size: 1em;
    margin-top: 1vh;
  }
}