.Settings {
  font-family: "Varela Round", serif;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: flex-start;
  direction: rtl;

  .FormTitle {
    text-align: right;
    margin-right: 7vw;
    margin-top: 0;
    margin-bottom: 0;
    color: black;
    font-size: 24px;
  }

  .FormSubtitle {
    text-align: right;
    margin-right: 7vw;
    margin-top: 0;
    margin-bottom: 0;
    color: #696969;
    font-size: 20px;
  }

  .SettingsForm {
    display: flex;
    flex-direction: column;
    margin-right: 7vw;
    margin-top: 0;
    margin-bottom: 30px;

    .SettingsField {
      margin-right: 15px;

      .NumPassengers{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 2vh;
        margin-bottom: 0.3vh;
        max-width: 220px;
      }
    }
  }
}