.registeredUsers {
  font-family: "Varela Round", serif;
  display: flex;
  flex-direction: column;

  .header {
    font-size: 1.7em;
    margin: 1vh 20px;
    text-align: right;
    white-space: pre-line;
  }

  .stationNode {
    background-color: #c71c21;
  }

  .label {
    font-size: 20px;
    padding: 0;
  }

  .closedLabel {
    .label();
    color: #8c8c8c;
  }

  .userRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    height: 30px;
    width: 100%;

    .username {
      margin-right: 50px;
    }
    .numRegistered {
      display: flex;
      align-items: center;
      margin-right: auto;

      svg {
        margin-right: 5px;
      }
    }

    .phone {
      margin-right: 10px;
    }
  }
}
