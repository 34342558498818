@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/varelaround/v20/w8gdH283Tvk__Lua32TysjIvoA.ttf) format('truetype');
}
.Home {
  font-family: "Varela Round", serif;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: flex-start;
  direction: rtl;
}
.Home .LoadingBuses {
  align-self: center;
  padding: 30px;
}
.Home .FirstRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.Home .ContentSubtitle {
  text-align: right;
  margin-right: 7vw;
  margin-top: 3vh;
  margin-bottom: 3vh;
  color: black;
  font-size: 24px;
  direction: rtl;
}
.Home .noBusesContainer {
  align-self: center;
  text-align: center;
}
.Home .noBusesContainer .logosContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2vh;
}
.Home .noBusesContainer .logosContainer .logo {
  width: 20vh;
  height: 20vh;
}
.Home .GameCard {
  display: flex;
  background-color: #d5d5d5;
  justify-content: right;
  margin-top: 1vh;
  border-radius: 15px;
  flex-direction: column;
  align-self: center;
  margin-bottom: 3vh;
  margin-right: 2vh;
  margin-left: 2vh;
}
.Home .GameCard ul li {
  margin-bottom: 1vh;
}
.Home .GameCard ul .row {
  margin-left: 15px;
}
.Home .GameCard .LogoInfo {
  display: flex;
  flex-direction: row;
}
.Home .GameCard .LogoInfo .OpponentLogo {
  width: 15vh;
  height: 15vh;
  align-self: center;
  margin-right: 15px;
}
.Home .GameCard .LogoInfo .GameInfo {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 1vw;
  margin-left: 20px;
  margin-bottom: 2vh;
}
.Home .GameCard .LogoInfo .GameInfo p {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 1vh;
}
.Home .GameCard .LogoInfo .GameInfo .OpponentName {
  margin-bottom: 2vh;
}
.Home .GameCard .LogoInfo .GameInfo .TicketsLink {
  color: blue;
}
.Home .GameCard .LogoInfo .BusOptions {
  margin-top: 20px;
  margin-left: 10px;
  height: fit-content;
  color: #000000;
}
.Home .GameCard .StationInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}
.Home .GameCard .StationInfo .NavigationLogoLinks {
  display: flex;
  flex-direction: row;
  margin-right: auto;
  padding-right: 10px;
  margin-top: 0;
  margin-left: 0;
}
.Home .GameCard .StationInfo .NavigationLogoLinks .NavigationLogo {
  width: 30px;
  height: 30px;
  margin-left: 15px;
  margin-top: 0;
  cursor: pointer;
}
.Home .GameCard .PriceList {
  margin-bottom: 5px;
}
.Home .GameCard .MemberInfo {
  margin-right: 3vh;
  margin-left: 3vh;
  margin-bottom: 15px;
}
.Home .GameCard .MemberInfo .MemberLink {
  color: blue;
}
.Home .RegisterButton {
  align-self: center;
  margin-top: 0;
  margin-bottom: 3vh;
  border: 0;
  background-color: #df1d22;
  color: white;
  border-radius: 15px;
  font-size: 24px;
  padding: 2vh;
  font-family: "Varela Round", serif;
  cursor: pointer;
}
.Home .RegisterButton:disabled {
  color: #e7e7e7;
  background-color: #c71c21;
}
.Home .RegisterButton:hover {
  color: #e7e7e7;
}
.Home .RegisterButtonLink {
  align-self: center;
}
.Home .FacebookButton {
  align-self: center;
  margin-top: 0;
  border: 0;
  background-color: #df1d22;
  color: white;
  border-radius: 15px;
  font-size: 22px;
  padding: 2vh;
  display: flex;
  align-items: center;
  font-family: "Varela Round", serif;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 10px;
  min-width: 220px;
}
.Home .FacebookButton:hover {
  color: #e7e7e7;
}
.Home .FacebookButton div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.NotSignedDialog {
  display: flex;
  flex-direction: column;
  font-family: "Varela Round", serif;
  background-color: white;
  align-items: flex-start;
  direction: rtl;
}
.NotSignedDialog p {
  margin-top: 20px;
}
.NotSignedDialog .SignUpInfo {
  display: flex;
  align-self: center;
  max-width: 300px;
  margin-right: 5vw;
  line-height: 1.5em;
}
.NotSignedDialog .RegisterButton {
  align-self: center;
  margin-top: 0;
  margin-bottom: 3vh;
  border: 0;
  background-color: #df1d22;
  color: white;
  border-radius: 15px;
  font-size: 24px;
  padding: 2vh;
  font-family: "Varela Round", serif;
  cursor: pointer;
}
.NotSignedDialog .RegisterButton:disabled {
  color: #e7e7e7;
  background-color: #c71c21;
}
.NotSignedDialog .RegisterButton:hover {
  color: #e7e7e7;
}
.NotSignedDialog .RegisterButtonLink {
  align-self: center;
}
