.SubmitDialog {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Varela Round", serif;

  .DialogTitle {
    align-self: flex-start;
    font-size: 1.5em;
    margin-right: 3vw;
  }

  .SignUpInfo {
    display: flex;
    align-self: center;
    max-width: 300px;
    margin-right: 5vw;
    margin-top: 0;
    line-height: 1.5em;
  }

  .SignInForm {
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    max-width: 220px;

    .EnterButton {
      width: 80px;
      height: 30px;
      align-self: center;
      background-color: #df1d22;
      color: white;
      border: 0;
      border-radius: 10px;
      font-size: 1.1em;
      font-family: "Varela Round", serif;
      cursor: pointer;
      margin-top: 10px;
    }

    .EnterButton:hover {
      color: #e7e7e7;
    }
  }

  .NoUserText {
    display: flex;
    flex-direction: row;
    align-self: center;
    margin: 0 20px;

    .RegisterText {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .NoUserText p {
    margin: 10px 0;
  }

  .SuccessMessage {
    text-align: center;
    margin-right: 3vw;
    margin-left: 3vw;
    margin-bottom: 0;
    font-size: 1.5em;
    line-height: 1.75;
  }

  .SubmitButton {
    align-self: center;
    margin: 3vh 1vw;
    border: 0;
    background-color: #df1d22;
    color: white;
    border-radius: 15px;
    font-size: 24px;
    padding: 2vh;
    font-family: "Varela Round", serif;
    cursor: pointer;
  }

  .SubmitButton:hover {
    color: #e7e7e7;
  }
}