.signIn {
  font-family: "Varela Round", serif;
  display: flex;
  flex-direction: column;
}

.DialogTitle {
  align-self: flex-start;
  font-size: 1.5em;
  margin-right: 3vw;
}

.SignInForm {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  max-width: 220px;

  .EnterButton {
    width: 80px;
    height: 30px;
    align-self: center;
    background-color: #df1d22;
    color: white;
    border: 0;
    border-radius: 10px;
    font-size: 1.1em;
    font-family: "Varela Round", serif;
    cursor: pointer;
    margin-top: 10px;
  }
}

.NoUserText {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 0 20px;
}
.NoUserText p {
  margin: 10px 0;
}

.RegisterText {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}