.BusRegister {
  font-family: "Varela Round", serif;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: flex-start;
  direction: rtl;

  .LoadingDialog {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
  }

  .SubmitButton:hover {
    color: #e7e7e7;
  }

  .FormTitle {
    text-align: right;
    margin-right: 7vw;
    margin-top: 0;
    margin-bottom: 0;
    color: black;
    font-size: 24px;
  }

  .BusForm {
    display: flex;
    flex-direction: column;
    margin-right: 7vw;
    margin-top: 2vh;

    .ChooseOpponent {
      display: flex;
      flex-direction: column;
      margin-top: 2vh;
      margin-bottom: 0.3vh;

      .AddBusInput {
        margin-bottom: 0;
      }
    }

    .AddBusInput {
      margin-bottom: 0;
    }
  }
}


.SubmitDialog {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Varela Round", serif;

  .SuccessMessage {
    text-align: center;
    margin-right: 3vw;
    margin-left: 3vw;
    margin-bottom: 0;
    font-size: 1.5em;
    line-height: 1.75;
  }
}

.SubmitButton {
  align-self: center;
  margin: 3vh 1vw;
  border: 0;
  background-color: #df1d22;
  color: white;
  border-radius: 15px;
  font-size: 24px;
  padding: 2vh;
  font-family: "Varela Round", serif;
  cursor: pointer;
}

.SubmitButton:hover {
  color: #e7e7e7;
}

