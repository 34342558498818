@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/varelaround/v20/w8gdH283Tvk__Lua32TysjIvoA.ttf) format('truetype');
}
.Home {
  font-family: "Varela Round", serif;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: flex-start;
  direction: rtl;
}
.Home .LoadingBuses {
  align-self: center;
  padding: 30px;
}
.Home .ContentSubtitle {
  text-align: right;
  margin-right: 7vw;
  margin-top: 3vh;
  margin-bottom: 3vh;
  color: black;
  font-size: 24px;
  direction: rtl;
}
.Home .noBusesContainer {
  align-self: center;
  text-align: center;
}
.Home .GameCard {
  display: flex;
  background-color: #d5d5d5;
  justify-content: right;
  margin-top: 1vh;
  border-radius: 15px;
  flex-direction: column;
  align-self: center;
  margin-bottom: 3vh;
}
.Home .GameCard .LogoInfo {
  display: flex;
  flex-direction: row;
}
.Home .GameCard .LogoInfo .OpponentLogo {
  width: 15vh;
  height: 15vh;
  align-self: center;
  margin-right: 15px;
}
.Home .GameCard .LogoInfo .GameInfo {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 1vw;
  margin-left: 20px;
  margin-bottom: 2vh;
}
.Home .GameCard .LogoInfo .GameInfo p {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 1vh;
}
.Home .GameCard .LogoInfo .GameInfo .OpponentName {
  margin-bottom: 2vh;
}
.Home .RegisterButton {
  align-self: center;
  margin-top: 0;
  margin-bottom: 3vh;
  border: 0;
  background-color: #df1d22;
  color: white;
  border-radius: 15px;
  font-size: 24px;
  padding: 2vh;
  font-family: "Varela Round", serif;
  cursor: pointer;
}
.Home .RegisterButton:disabled {
  color: #e7e7e7;
  background-color: #c71c21;
}
.Home .RegisterButton:hover {
  color: #e7e7e7;
}
