.LoadingDialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
}

.SubmitDialog {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Varela Round", serif;

  .DialogTitle {
    align-self: flex-start;
    font-size: 1.5em;
    margin-right: 3vw;
  }

  .CancelInfoText {
    margin: 5px 20px;
    text-align: center;
  }

  .ConfirmCancel {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    align-self: center;
  }

  .SuccessMessage {
    text-align: center;
    margin-right: 3vw;
    margin-left: 3vw;
    margin-bottom: 0;
    font-size: 1.5em;
    line-height: 1.75;
  }

  .SubmitButton {
    align-self: center;
    margin: 3vh 1vw;
    border: 0;
    background-color: #df1d22;
    color: white;
    border-radius: 15px;
    font-size: 24px;
    padding: 2vh;
    font-family: "Varela Round", serif;
    cursor: pointer;
  }

  .SubmitButton:hover {
    color: #e7e7e7;
  }
}